import request from '../../utils/request';

/**
 * 获取体测成绩
 * @param query
 * @returns {AxiosPromise}
 */
export const getTranscript = (param) => {
    return request({
        url: '/sys/sportTest/page',
        method: 'GET',
        params:param
    });
}
/***
 * 导入
 * @param query
 * @returns {AxiosPromise}
 */
export const importFile = (file) => {
    console.log(file)
    return request({
        url: '/sys/sportTest/importFile',
        method: 'post',
        data: file
    });
};
/**
 * 导出二维码表格
 * @param query
 * @returns {AxiosPromise}
 */
export const fileExport = (param) => {
    return request({
        url: '/sys/sportTest/export',
        method: 'get',
        responseType: 'arraybuffer',
        params: param
    });
}
/**
 * 导出学员成绩
 * @param query
 * @returns {AxiosPromise}
 */
export const exportResults = (param) => {
    return request({
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        url: '/sys/sportTest/exportFile',
        method: 'get',
        responseType: 'arraybuffer',
        params: param
    });
}

