<template>
  <div style="height: 100%;padding: 20px">
    <div class="handle-box">
      <!--搜索区-->
      <el-input v-model="query.school" placeholder="学校" class="handle-input mr10"></el-input>
      <el-input v-model="query.gradeName" placeholder="年级" class="handle-input mr10"></el-input>
      <el-input v-model="query.className" placeholder="班级" class="handle-input mr10"></el-input>
      <el-input v-model="query.name" placeholder="姓名" class="handle-input mr10"></el-input>
      <el-input v-model="query.sex" placeholder="性别" class="handle-input mr10"></el-input>
      <el-input v-model="query.studentIdentity" placeholder="学号" class="handle-input mr10"></el-input>

      <!--按钮区-->
      <el-button type="primary" icon="Search" @click="loadPageData(1)">搜索</el-button>
      <el-button type="primary" icon="Search" @click="ImportFile=true">导入</el-button>
      <br/>

      <!--居中 按钮区-->
      <div style="padding: 15px 0;width: 1000px;margin: 0 auto;">
<!--        暂时只放导入,不放导出-->
<!--        <el-button type="primary" icon="Search" @click="loadPageData(1)"></el-button>

        <el-button type="primary" icon="Search" @click="ImportFile=true">导入</el-button>-->

        <el-popconfirm title="确定导出带二维码的学员信息吗?" @confirm="fileExport()">
          <template #reference>
            <el-button type="primary" icon="Search">导出学员信息</el-button>
          </template>
        </el-popconfirm>

        <el-popconfirm title="确定导出学员成绩吗?" @confirm="exportResults()">
          <template #reference>
            <el-button type="primary" icon="Search">导出学员体测成绩</el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>

    <!--列表-->
    <el-table :data="testStudentTableData" border
              :row-style="{height:2+'px'}"
              :cell-style="{color:'#909399',fontSize:'12.5px',padding:'6px 0px'}"
              :header-cell-style="{background:'#ffff !important;'}"
    >
      <el-table-column label="ID" prop="id"/>
      <el-table-column label="学生姓名" prop="name"/>
      <el-table-column label="性别" prop="sex" show-overflow-tooltip="true"/>
      <el-table-column label="学校" prop="school"/>
      <el-table-column label="班级" prop="className"/>
      <el-table-column label="测试编号" prop="studentIdentity"/>
      <el-table-column label="联系方式" prop="parentPhone"/>

      <el-table-column label="创建时间" prop="createTime" min-width="110"/>

      <el-table-column label="操作">
        <template #default="scope">
          <el-button @click="showTestResult(scope.row)">查看成绩</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页-->
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          v-loading="tableLoading"
          :current-page="current"
          :total="total"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <!-- 查看单个学员体测的成绩 弹框 -->
    <el-dialog title="体测成绩" v-model="oneSportsTestStudentDialog" width="600px" center>
      <div class="transcript">
        <div
            style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);border-radius: 25px;padding: 20px;width: 72%;margin-left:10%;">

          <div style="font-weight:bolder;font-size:20px">
            <span>学生学号：{{ oneRowData.studentIdentity }}</span>
            <el-divider style="margin: 0 30px" direction="vertical"></el-divider>
          </div>
          <div style="font-weight:bolder;font-size:20px">
            <span>学生姓名：{{ oneRowData.name }}</span>
            <el-divider style="margin: 0 30px" direction="vertical"></el-divider>
            <span>学生性别：{{ oneRowData.sex }}</span>
          </div>

          <div style="font-weight:bolder;font-size:20px">
            <span>所在学校：{{ oneRowData.school }}</span>
            <el-divider style="margin: 0 30px" direction="vertical"></el-divider>
            <span>所在班级：{{ oneRowData.className }}</span>
          </div>
          <el-table style="margin-top: 40px;font-size: 20px;font-weight: bold;"
                    :data="oneRowData.testResultsVOS" :row-style="{height:'2px'}">
            <el-table-column prop="sportsProjectName" label="项目"/>
            <el-table-column prop="score" label="成绩"/>
            <el-table-column prop="results" label="得分"/>
          </el-table>
        </div>
      </div>
      <div style="display: flex;justify-content: center;padding-top: 30px">
        <el-button :size="small" type="primary" @click="oneSportsTestStudentDialog=false">确定</el-button>
      </div>
    </el-dialog>

    <!--    导入Dialog-->
    <el-dialog v-model="ImportFile" width="500px">
      <div style="display: flex;justify-content: center">
        <el-upload drag :limit="limitNum" :auto-upload="false" :on-remove="handleRemove"
                   :on-change="fileChange" :on-exceed="exceedFile" :file-list="fileList">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="display: flex;justify-content: center;padding-top: 30px">
        <el-button :size="small" type="primary" @click="importFiles">导入数据</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getTranscript, importFile, fileExport, exportResults} from "@/api/transcript/transcript";
import {ElLoading} from 'element-plus'

export default {
  name: "Transcript",
  data() {
    return {
      limitNum: 1, // 上传excell时，同时允许上传的最大数
      fileList: [],
      ImportFile: false,
      testStudentTableData: [],
      total: 0,
      current: null,
      query: {
        page: 1,
        pageSize: 10,//每页条数
        name: "",
        sex: "",
        school: "",
        gradeName: "",
        studentIdentity: "",
        className: "",
      },
      oneSportsTestStudentDialog: false,
      tableLoading: false,
      oneRowData: null,
    }
  },
  mounted() {
    console.log(this.query)
    console.log("-----page:" + this.query.page + "----current:" + this.current)
    // if (this.query.page !== this.current) {
    //进入加载列表
    this.loadPageData(1);
    // }
  },
  created() {
  },
  methods: {
    // 搜索 列表
    loadPageData(e) {
      //选择加载第几页
      this.query.page = e;
      console.log("load:");
      getTranscript(this.query).then(val => {
        //加载数据
        this.testStudentTableData = val.data.records;
        //设置结果值
        this.total = val.data.total
        this.current = val.data.current
        this.query.page = val.data.current
        console.log("返回的current" + this.current)

        if (val.data.records.length > 0) {
          //初始化一行
          this.oneRowData = val.data.records[0]
        }
        // console.log(val)
      })
    },
    /**
     * 学生信息导出
     */
    fileExport() {
      this.openLoading();
      fileExport(this.query).then(res => {
        this.loading = false
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a'); //我们用模拟q标签点击事件
        const fname = '订单统计信息'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
        ElLoading.service({background: 'rgba(0, 0, 0, 0.7)'}).close();
      })
    },
    /**
     * 学生各项体测成绩导出
     */
    exportResults() {
      this.openLoading();
      exportResults(this.query).then(res => {
        this.loading = false
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a'); //我们用模拟q标签点击事件
        const fname = '订单统计信息'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click(); //点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
        ElLoading.service({background: 'rgba(0, 0, 0, 0.7)'}).close();
      })
    },
    /**
     * 导入学生信息
     */
    importFiles() {
      this.openLoading();
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        importFile(form).then(val => {
          if (val.code == 200) {
            ElLoading.service({background: 'rgba(0, 0, 0, 0.7)'}).close();
            // this.Cal = false
            this.$message.success('导入成功');
            this.fileList = []
          } else {
            this.$message.warning('导入失败,');
          }
        })
      }
    },
    /**文件移除*/
    handleRemove() {
      this.fileList = []
    },
    //文件状态改变时的钩子
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== 'xlsx') {
        this.fileList = []
        this.$message.warning('只能上传后缀是.xlsx的文件');
      }
      if (size > 10) {
        this.fileList = []
        this.$message.warning('文件大小不得超过100M');
      }
      if (extension == 'xlsx' && size < 10) {
        this.fileList.push(file.raw);
      }
    },
    //收到文件
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择 ${1} 个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    /**
     * 加载中loading的弹出层
     */
    openLoading() {
      const loading = ElLoading.service({           // 声明一个loading对象
        lock: true,                             // 是否锁屏
        text: '请稍等...',                     // 加载动画的文字
        spinner: 'el-icon-loading',             // 引入的loading图标
        background: 'rgba(0, 0, 0, 0.7)',      // 背景颜色
        target: '.sub-main',                    // 需要遮罩的区域
        body: true,
        customClass: 'mask'                     // 遮罩层新增类名
      })
      setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
        loading.close();                        // 关闭遮罩层
      }, 120000)
    },
    //分页点击事件
    handlePageChange(e) {
      console.log("handle:" + e);
      if (this.current !== e) {
        this.query.page = e
        this.current = e
        this.loadPageData(e)
      }
    },
    //查看成绩
    showTestResult(rowData) {
      console.info(rowData)
      console.info("点击查看详情")
      this.oneRowData = rowData;
      this.oneSportsTestStudentDialog = true;
    }


  }
}
</script>

<style scoped>
.handle-input {
  width: 200px;
  display: inline-block;
}

.mr10 {
  margin-right: 10px;
}

.transcript {
  background-color: white;
  border-radius: 10px;
  color: #83868b;
  height: 78%;
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
}
</style>
